<template>
  <font-awesome-icon
    :icon="icon"
    :size="size"
    alt="information"
    boundary=""
    v-tooltip.left-end="{
      content: tooltip,
      trigger: 'click hover',
      autoHide: true,
    }"
  />
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
    },
    icon: {
      type: String,
    },
    size: {
      type: String,
    },
  },
};
</script>
